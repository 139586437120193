import React, { ReactElement } from "react"

// import Bio from "../components/bio"
// import SEO from "../components/seo"

require("../templates/section.less");

import { BasePage } from '../components/Base Page'

import { Image, LogoString, SkullString } from '../../../shared/hmwrk/images';
import { CenteredBlock } from "../components/Stacked Block";

import { PageSection } from '../components/Base Page/abstract'

import lordvirr from '../images/lordvirr.png'
import bogHead from '../images/bog_head_pixel.png'
import bog from '../images/bog_logo.png'

export const testSections: PageSection[] = [
  {
    title: "Softwrk",
    path: "softwrk",
    pages: [],//[{path: ""}]
  },
  {
    title: "Clothwrk",
    path: "",
    pages: []
  },
  {
    title: "Soundwrk",
    path: "",
    pages: []
  },
  {
    title: "Bookwrk",
    path: "",
    pages: []
  },
]

interface HomePageState {
}

class HomePage extends React.Component<{}, HomePageState> {
  public static readonly displayName = "HomePage";

  lastScrollTop: number = 0;
  lastScrollLeft: number = 0;

  static threshold: number = 100;

  constructor(props) {
    super(props);
  }

  public render() {
    return (
      <BasePage 
        children={[
          <div className="pageItemsContainer" children={[
            <a href="https://lordvirr.com"> <img src={lordvirr} /> </a>,
            <a href="https://bog.computer" > <img src={bog} /> </a>,
          ]} />
        ]}
        sections={testSections} 
        currentSection={
          null
        }
      />
    )
  }
}

export default HomePage;
