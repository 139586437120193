import React, { ReactElement } from 'react';
import Link from 'gatsby-link'
import { Footer } from '../../../../shared/hmwrk/footer'
import Helmet  from 'react-helmet'

import { graphql, StaticQuery } from 'gatsby'

import { Textwrk } from '../../../../shared/hmwrk/textwrk';

import { PageSection } from './abstract'
import hmwrkLogo from '../../images/hmwrk logo.png'

// tslint:disable-next-line:no-var-requires
require("./index.less");

const sectionColor = (section: PageSection | undefined) : string => {
  return "hmwrk-color" // `${section?.path ?? "hmwrk"}-color`;
}

const sectionBorderColor = (section: PageSection | undefined) : string => {
  return `${section?.path ?? "hmwrk"}-border-color`;
}

const sectionOutlineText = (section: PageSection | undefined) : string => {
  return `${section?.path ?? "hmwrk"}-outline-text`;
}

const sectionBackground = (section: PageSection | undefined) : string => {
  return `${section?.path ?? "hmwrk"}-background`;
}

interface BasePageProps extends React.HTMLProps<HTMLDivElement> {
  children: any,
  sections: PageSection[],
  title?: string,
  currentSection?: PageSection,
}

interface BasePageState extends React.HTMLProps<HTMLDivElement> {
}


const Message = (msg: string, key: string) => {
  return <div 
    key={key}
    style={{
      display: "block",
      position: "fixed",
      pointerEvents: "none",
      left: "50%",
      top: "55%",
      transform: "translate(-50%, -50%)",
      color: "white",
      background: "black",
      fontSize: "11px",
      textAlign: "center"
    }} > { msg } </div>
}

export class BasePage extends React.Component<BasePageProps, BasePageState> {

  constructor(props) {
    super(props);
  }

  mouseDiv = <div style={{width:"100px", height:"100px", top: "-100px", left: "-100px", background: "red"}} />
  
  textwrk(section: PageSection | undefined, color: string): ReactElement<{}> {
    let highlighted = section?.path == this.props.currentSection?.path;
    const href = `/${section?.path ?? ""}`;

    let name: string;
    let opacity: number = 1;
    if (section) {
      name = `${section.title} (${section.pages.length > 0 ? section.pages.length : "NULL"})`;
      opacity = 0.5;
    } else {
      name = "Hmwrk.net"
    }

    return <a className={`PageMenu-Container ${sectionColor(section)}`} href={href.length > 0 ? href : null}>
      <Textwrk 
        name={name} 
        highlighted={highlighted} 
        key={name}
        style={{
          opacity: opacity
        }}
      />
    </a>
  }

  public render() {
    
    let navItems: [ReactElement<{}>?] = [this.textwrk(null, "hmwrk")];

    for(const section of this.props.sections) {
      if(section.pages.length > 0) {
        navItems.push(
          this.textwrk(section, section.path)
        )
      }
    }

    // fixme: wtf
    const helmet = <Helmet
      title={`Hmwrk.net ${this.props.title ? `(${this.props.title})` : ""}`}
      meta={[
        { name: 'description', content: 'HMWRK hmwrk Hmwrk' },
        { name: 'keywords', content: 'Hmwrk, homework, art, software, music, writing, experiences, wrk' },
      ]}
    />;
    

    return <div key="basepage" className="Page">
      { helmet }
      <div className="PageContent">
        {this.props.children}
      </div>
      <nav className="PageMenu" children={[
          <img style={{position:"absolute",  objectFit: "contain", opacity:"0.3", }} src={hmwrkLogo} />,
        ...navItems
      ]} />
      {/* <Footer href="/" name="Hmwrk, Inc." /> */}
    </div>
  }
}

