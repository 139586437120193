import * as React from 'react';

// tslint:disable-next-line:no-var-requires
require("./index.less");

interface TextwrkProps extends React.HTMLProps<HTMLDivElement> {
  name: string,
  highlighted: boolean,
  key: string
}

export class Textwrk extends React.Component<TextwrkProps, {}> {
  public render() {

    let arr: [React.ReactElement<{}>?] = [];
    let numWrk = (this.props.name.match(/wrk/) || []).length;

    const isHighlighted = this.props.highlighted ? "-highlighted" : "";

    this.props.name.split("wrk").forEach(function(str, index) {
        arr.push(
            <div className="Textwrk" key={`${index}`} >{str}</div>,
        )
        if (numWrk-- > 0) {
            arr.push(
                <div className={`Textwrk-w Textwrk-w`} key={`w${index}`} >w</div>,
                <div className={`Textwrk-r Textwrk-r`} key={`r${index}`} >r</div>,
                <div className={`Textwrk-k Textwrk-k`} key={`k${index}`} >k</div>,
            )
        }
    });

    return (
        <div key={this.props.key} className={`Textwrk-container${isHighlighted}`} style={this.props.style} >
            {arr}
        </div>
    )
  }
}

export function HmwrkInc(): React.ReactElement<{}> {
    return <div className="Textwrk-inc">
        <div className="Textwrk">HM</div>
        <div className="Textwrk-inc-w">W</div>
        <div className="Textwrk-inc-r">R</div>
        <div className="Textwrk-inc-k">K</div>
        <div className="Textwrk">, Inc.</div>
    </div>;
}
